import { ContactUsButtons } from "../components/ContactUsButtons";

export function ContactPage() {
    return (
        <>
            <section className="hero-image hero-image-james justify-content-start align-items-center w-100 d-flex text-light">
                <div className="text-light p-5 w-100">
                    <div className="d-lg-none" style={{ height: "300px" }}></div>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <img src="img/White logo - no background - small.png" alt="RiFiTi" />
                        </div>
                        <div className="col-12 col-lg-8">
                            <ContactUsButtons />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}