import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ContactUsButtons() {
    return (
        <div className="row">
            <div className="col-12 col-lg-6 mb-5 mb-lg-0">
                <a href="mailto:contact@rifiti.dk" className="btn btn-gold w-100 p-3">
                    <span className="h3">
                        <FontAwesomeIcon icon={faEnvelopeOpen} /> Contact us by email
                    </span>
                    <br />
                    <span className="h5">
                        contact@rifiti.dk
                    </span>
                </a>
            </div>
            <div className="col-12 col-lg-6 mb-5 mb-lg-0">
                <a href="tel:+4531666418" className="btn btn-gold w-100 p-3">
                    <span className="h3">
                        <FontAwesomeIcon icon={faPhoneVolume} /> Contact us by phone
                    </span>
                    <br />
                    <span className="h5">
                        +45 31 666 418
                    </span>
                </a>
            </div>
        </div>
    );
}