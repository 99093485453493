import React from "react";
import { Route, Routes } from "react-router-dom";
import { AboutPage } from "./pages/AboutPage";
import { OurDeliveryPage } from "./pages/OurDeliveryPage";
import { FrontPage } from "./pages/FrontPage";
import { ContactPage } from "./pages/ContactPage";

export function Router() {
    return (
        <Routes>
            <Route path="/" element={<FrontPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/our-delivery" element={<OurDeliveryPage />} />
            <Route path="/contact" element={<ContactPage />} />
        </Routes>
    );
}