import { ContactUsButtons } from "../components/ContactUsButtons";

export function OurDeliveryPage() {
    return (
        <>
            <section className="container my-5">

                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6">
                        <img className="object-fit-cover w-100 rounded-5 border-0"
                            src="img/kelly-sikkema-lFtttcsx5Vk-unsplash.jpg"
                            alt="Skyscrabers" />
                    </div>
                </div>

            </section>

            <section className="container my-5">

                <h1 className="text-center">Our delivery</h1>

                <div className="row justify-content-around align-items-center">
                    <div className="col-lg col-12">
                        <h2 className="h3">Analysis</h2>
                        <p>First we analyse your business to help define your <b>core value streams</b></p>
                    </div>

                    <div className="col display-4 text-center d-lg-block d-none">
                        <i className="fa-solid fa-angles-right"></i>
                    </div>
                    <div className="col display-4 text-center d-lg-none d-block">
                        <i className="fa-solid fa-angles-down"></i>
                    </div>

                    <div className="col-lg col-12">
                        <h2 className="h3">D365</h2>
                        <p>Then we take a look at standard D365 in regards to your <b>needs and core processes</b></p>
                    </div>

                    <div className="col display-4 text-center d-lg-block d-none">
                        <i className="fa-solid fa-angles-right"></i>
                    </div>
                    <div className="col display-4 text-center d-lg-none d-block">
                        <i className="fa-solid fa-angles-down"></i>
                    </div>

                    <div className="col-lg col-12">
                        <h2 className="h3">ISV</h2>
                        <p>After that we look at the ISV solutions that can help you close gaps in your <b>end-to-end processes</b></p>
                    </div>

                    <div className="col display-4 text-center d-lg-block d-none">
                        <i className="fa-solid fa-angles-right"></i>
                    </div>
                    <div className="col display-4 text-center d-lg-none d-block">
                        <i className="fa-solid fa-angles-down"></i>
                    </div>

                    <div className="col-lg col-12">
                        <h2 className="h3">Solution</h2>
                        <p>Lastly we design and build the solution - tailored to cover your needs with <b>right first time</b> focus</p>
                    </div>
                </div>

            </section>

            <section className="container my-5">

                <h1 className="text-center">Deep dive</h1>

                <div className="accordion accordion-gold" id="ourDelivery">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#ourDeliveryCollapseA" aria-expanded="true" aria-controls="ourDeliveryCollapseA">
                                <span className="h3 mb-0">
                                    Analysis
                                </span>
                            </button>
                        </h2>
                        <div id="ourDeliveryCollapseA" className="accordion-collapse collapse show" data-bs-parent="#ourDelivery">
                            <div className="accordion-body">
                                <ul>
                                    <li className="my-3">
                                        Defining the CORE VALUE STREAMS that drive the business model and the value propositions as perceived by the business’ customers
                                    </li>
                                    <li className="my-3">
                                        Describing functional areas and map which IT applications that are involved in the core value streams.
                                        <ul>
                                            <li className="my-3">
                                                How integrated or separated are the functional areas (silo, overlap, duplicated work/data ownership)?
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="my-3">
                                        First rough visualization of main value streams
                                        <ul>
                                            <li className="my-3">
                                                Strategic partnering, Contract life cycle, Procurement strategies
                                            </li>
                                            <li className="my-3">
                                                Design =&gt; product engineering =&gt; product life cycle management
                                            </li>
                                            <li className="my-3">
                                                Forecasting=&gt; Selling =&gt; Purchasing =&gt; Manufacturing =&gt; Delivering
                                            </li>
                                            <li className="my-3">
                                                Quality handling in warehouse, production and shipping
                                            </li>
                                            <li className="my-3">
                                                Etc.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <h3>
                                    Outcome
                                </h3>
                                <ul>
                                    <li className="slogan">
                                        Right First Time
                                    </li>
                                    <li>
                                        First rough drawing of
                                        <ul>
                                            <li>
                                                Future application landscape
                                            </li>
                                            <li>
                                                Consequential application retirement plan
                                            </li>
                                            <li>
                                                Impact on D365 scope on short and long term
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Initial data collection for the first pilot
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ourDeliveryCollapseB" aria-expanded="true" aria-controls="ourDeliveryCollapseB">
                                <span className="h3 mb-0">
                                    D365 First Pilot
                                </span>
                            </button>
                        </h2>
                        <div id="ourDeliveryCollapseB" className="accordion-collapse collapse" data-bs-parent="#ourDelivery">
                            <div className="accordion-body">
                                <ul>
                                    <li>
                                        Present/demonstrate all envisaged standard processes inside D365
                                        <ul>
                                            <li>
                                                D365 Solution configured to best practice based on business value streams
                                            </li>
                                            <li>
                                                Based on the expected short term D365 scope
                                            </li>
                                            <li>
                                                Using real company sample data
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Define first vs. later delivery waves, based on solution scope and companies in scope
                                    </li>
                                </ul>
                                <h3 className="h4">
                                    Outcome
                                </h3>
                                <ul>
                                    <li className="slogan">
                                        Right First Time
                                    </li>
                                    <li>
                                        Confirm all level 3 processes required by the business
                                    </li>
                                    <li>
                                        Close the delivery track for all approved processes
                                    </li>
                                    <li>
                                        Gather the needed adjustments to standard configuration
                                    </li>
                                    <li>
                                        Gather additional requirements in order to deliver the core value streams
                                    </li>
                                    <li>
                                        Consider and analyze fit and gaps
                                    </li>
                                    <li>
                                        Solution design, focused only on value streams not delivered fully in First pilot
                                    </li>
                                    <li>
                                        Outline optional ISV solutions and integrations required to deliver core value streams
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ourDeliveryCollapseC" aria-expanded="false" aria-controls="ourDeliveryCollapseC">
                                <span className="h3 mb-0">
                                    D365 Second Pilot
                                </span>
                            </button>
                        </h2>
                        <div id="ourDeliveryCollapseC" className="accordion-collapse collapse" data-bs-parent="#ourDelivery">
                            <div className="accordion-body">
                                <ul>
                                    <li>
                                        Present/demonstrate processes that required adjustment in First Pilot in D365 Test
                                    </li>
                                    <li>
                                        Present/demonstrate the additional processes identified in First Pilot in D365 Test
                                        <ul>
                                            <li>
                                                D365 Solution configured to meet the scope specific requirements
                                            </li>
                                            <li>
                                                Using much larger population of real company data
                                            </li>
                                            <li>
                                                ISV solution and Gaps requiring modification still excluded
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Scope and agree solution design and scope for selected ISV solutions
                                    </li>
                                </ul>
                                <h3 className="h4">
                                    Outcome
                                </h3>
                                <ul>
                                    <li className="slogan">
                                        Right First Time
                                    </li>
                                    <li>
                                        Close the delivery track for processes not closed in First Pilot
                                    </li>
                                    <li>
                                        Gather the needed adjustments for processes still not closed
                                    </li>
                                    <li>
                                        Solution design, focused only on value streams not delivered fully in Second Pilot
                                    </li>
                                    <li>
                                        Agree and define ISV solutions and integrations required to deliver core value streams
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ourDeliveryCollapseD" aria-expanded="false" aria-controls="ourDeliveryCollapseD">
                                <span className="h3 mb-0">
                                    ISV First Pilot
                                </span>
                            </button>
                        </h2>
                        <div id="ourDeliveryCollapseD" className="accordion-collapse collapse" data-bs-parent="#ourDelivery">
                            <div className="accordion-body">
                                <ul>
                                    <li>
                                        Present/demonstrate all envisaged standard processes in ISV solution with integration to D365 Test
                                    </li>
                                    <li>
                                        Based on standard best practice configuration for defined processes and documents
                                    </li>
                                </ul>
                                <h3 className="h4">
                                    Outcome
                                </h3>
                                <ul>
                                    <li className="slogan">
                                        Right First Time
                                    </li>
                                    <li>
                                        Close the delivery track for all approved processes/documents
                                    </li>
                                    <li>
                                        Gather the needed adjustments to standard configuration
                                    </li>
                                    <li>
                                        Gather additional requirements in order to deliver the core value streams
                                    </li>
                                    <li>
                                        Consider and analyze fit and gaps
                                    </li>
                                    <li>
                                        Solution design, focused only on value streams not delivered fully in ISV First pilot
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ourDeliveryCollapseE" aria-expanded="false" aria-controls="ourDeliveryCollapseE">
                                <span className="h3 mb-0">
                                    ISV Second Pilot
                                </span>
                            </button>
                        </h2>
                        <div id="ourDeliveryCollapseE" className="accordion-collapse collapse" data-bs-parent="#ourDelivery">
                            <div className="accordion-body">
                                <ul>
                                    <li>
                                        Present/demonstrate processes that required adjustment in ISV First Pilot in D365 Test
                                    </li>
                                    <li>
                                        Present/demonstrate the additional processes identified in ISV First Pilot in D365 Test
                                        <ul>
                                            <li>
                                                D365 Solution configured to meet the scope specific requirements
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <h3 className="h4">
                                    Outcome
                                </h3>
                                <ul>
                                    <li className="slogan">
                                        Right First Time
                                    </li>
                                    <li>
                                        Close the delivery track for processes not closed in ISV First Pilot
                                    </li>
                                    <li>
                                        Gather the needed adjustments for processes still not closed
                                    </li>
                                    <li>
                                        Solution design, focused only on value streams not delivered fully in ISV Second Pilot
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ourDeliveryCollapseF" aria-expanded="false" aria-controls="ourDeliveryCollapseF">
                                <span className="h3 mb-0">
                                    Solution Third Pilot
                                </span>
                            </button>
                        </h2>
                        <div id="ourDeliveryCollapseF" className="accordion-collapse collapse" data-bs-parent="#ourDelivery">
                            <div className="accordion-body">
                                <ul>
                                    <li>
                                        After delivery of approved modification/development (not described above)
                                    </li>
                                    <li>
                                        We are ready for end-to-end testing of all core value processes
                                    </li>
                                    <li>
                                        Previously closed processes are re-confirmed including ISV interaction and delivered modifications
                                    </li>
                                    <li>
                                        Last modifications are adopted and retested
                                    </li>
                                </ul>
                                <h3 className="h4">
                                    Outcome
                                </h3>
                                <ul>
                                    <li className="slogan">
                                        Right First Time
                                    </li>
                                    <li>
                                        Solution is released for User Acceptance Test (UAT)
                                    </li>
                                    <li>
                                        Cut over activities are initiated
                                    </li>
                                    <li>
                                        Go-Live preparation
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container my-5">
                <div className="card shadow-lg">
                    <div className="card-body">
                        <h2 className="card-title">
                            Lets talk
                        </h2>
                        <p>
                            We would love to look at your case or chat about our offering
                        </p>
                        <ContactUsButtons />
                    </div>
                </div>
            </section>
        </>
    );
}