import React from "react";
import { Router } from "./Router";

function App() {
  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top navbar-dark bg-dark p-3">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="img/White logo - no background - small.png" alt="RiFiTi" className="logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="/">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/our-delivery">Our delivery</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/about">About</a>
              </li>
            </ul>
            <a href="/contact" className="btn btn-outline-light rounded-pill border-3">
              Contact us to hear more
            </a>
          </div>
        </div>
      </nav>

      <Router />

      <footer className="container-fluid bg-black text-light p-5 small">
        <div className="row">
          <div className="col-12 col-lg-6 px-lg-5">
            <div className="d-flex w-100 justify-content-start justify-content-lg-end">
              <div>
                <p>
                  <a className="text-light" href="tel:+4531666418">+45 31 666 418</a>
                </p>
                <p>
                  <a className="text-light" href="mailto:contact@rifiti.dk">contact@rifiti.dk</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 px-lg-5">
            <div className="d-flex w-100 justify-content-start">
              <div>
                <p>
                  No cookies means no cookie policy
                </p>
                <p>
                  No tracking means no privacy policy
                </p>
                <p>
                  <a className="link-underline-opacity-0 link-primary" href="https://linkedin.com/in/mikael-dittmann-9786781/">
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mt-5 text-center">
            <small className="text-light">Copyright &copy; Mikael Dittmann - All rights reserved</small>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
