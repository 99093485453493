import { ContactUsButtons } from "../components/ContactUsButtons";

export function FrontPage() {
    return (
        <>
            <section className="hero-image hero-image-charles justify-content-start align-items-center w-100 d-flex text-light">
                <div className="bg-dark text-light p-5 w-100 opacity-75">
                    <img src="img/White logo - no background - small.png" alt="RiFiTi" />
                    <p>
                        Microsoft Dynamics 365 for Finance &amp; Operation – Consultancy, Implementation and Support
                    </p>
                </div>
            </section>

            <section className="container my-5">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 p-5">
                        <img className="object-fit-cover w-100 rounded-5 shadow-lg border-0"
                            src="img/meeting-2284501_1920.jpg"
                            alt="Skyscrabers" />
                    </div>
                    <div className="col-12 col-lg-6 p-5">
                        <h2>
                            How we help your business
                        </h2>
                        <ul>
                            <li>
                                When you need D365 business process leads - but you miss free capacity
                            </li>
                            <li>
                                When you want flexible project resources as an alternative to finding and retaining skilled full time employees
                            </li>
                            <li>
                                When you want to implement at least part of the blue print solution yourself - but you miss qualified experience
                            </li>
                            <li>
                                When you prepare your requirements and specification - but want a 2nd opinion on fit and size of gaps
                            </li>
                            <li>
                                When we have your trust – we will bring in our associated partners and will set the right team for you
                            </li>
                            <li>
                                Together we will get it Right First Time – with less resources and better preparation
                            </li>
                        </ul>
                        <div className="text-center mb-3">
                            <a href="/contact" className="btn btn-gold p-3 fw-bold">Get in touch with us</a>
                        </div>
                        <p>
                            and let us discuss if we can get you on the journey and secure delivery <span className="slogan">Right First Time</span>.
                        </p>
                    </div>
                </div>
            </section>

            <section className="container-fluid my-5 bg-dark text-light">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 p-5 d-flex justify-content-lg-end">
                        <div>
                            <h2>
                                Let us be partners
                            </h2>
                            <ul>
                                <li>
                                    We can help you win projects and ensure that you have access to the required capacity to deliver
                                </li>
                                <li>
                                    We guarantee our expertise for the duration of the project
                                </li>
                                <li>
                                    We are in it to help you perform, deliver and succeed - not just supply you with resources
                                </li>
                                <li>
                                    Together we will get it <span className="slogan">Right First Time</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 p-5">
                        <img className="object-fit-cover w-100 rounded-5 border-0"
                            src="img/teamwork-3213924_1920.jpg"
                            alt="Skyscrabers" />
                    </div>
                </div>
            </section>

            <section className="container my-5">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 p-5">
                        <h2>
                            Are you our new colleague?
                        </h2>
                        <ul>
                            <li>
                                If you want to be part of an adventure, where the limit is our combined ability to turn imagination into reality
                            </li>
                            <li>
                                If you believe you have an entrepreneur inside that is eager to get out and make a difference
                            </li>
                            <li>
                                If you are tired of old-school management hierarchies and believe you can deliver better, managing your own turf
                            </li>
                            <li>
                                If you feel you should have part in the earnings you make from your effort in making our customers satisfied
                            </li>
                        </ul>
                        <div className="text-center mb-3">
                            <a href="/contact" className="btn btn-gold p-3 fw-bold">Get in touch with us</a>
                        </div>
                        <p>
                            and be part of they journey securing delivery <span className="slogan">Right First Time</span>.
                        </p>
                    </div>
                    <div className="col-12 col-lg-6 p-5">
                        <img className="object-fit-cover w-100 rounded-5 shadow-lg border-0"
                            src="img/ian-schneider-TamMbr4okv4-unsplash.jpg"
                            alt="Skyscrabers" />
                    </div>
                </div>
            </section>

            <section className="container my-5">
                <div className="card shadow-lg">
                    <div className="card-body">
                        <h2 className="card-title">
                            Lets talk
                        </h2>
                        <p>
                            We would love to look at your case or chat about our offering
                        </p>
                        <ContactUsButtons />
                    </div>
                </div>
            </section>
        </>
    );
}