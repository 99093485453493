import { ContactUsButtons } from "../components/ContactUsButtons";

export function AboutPage() {
    return (
        <>
            <section className="container-fluid my-5 bg-dark">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-4 p-5 text-center">
                        <img className="object-fit-cover w-75 rounded-circle shadow-lg border-0" src="img/1587546419404.jpg"
                            alt="Mikael Dittmann" />
                    </div>
                    <div className="col-12 col-lg-8 p-5 text-light">
                        <h1 className="display-1">
                            About RiFiTi
                        </h1>
                        <p className="h2">
                            I am Mikael Dittmann CEO and owner of Dittmann Consulting A/S,
                            which in turn is the only shareholder of RiFiTi ApS located in Denmark/Copenhagen and RiFiTi AB
                            located in Sweden/Malmo.
                        </p>
                    </div>
                </div>
            </section>

            <section className="container my-5">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 p-5">
                        <h2>
                            My history
                        </h2>
                        <p>
                            I have worked with AX as user-professional since 2002, with a few career sidesteps on CXAL, AS400 and Navision.
                        </p>
                        <p>
                            The first 20+ years of my career I grew from controller functions to Group CFO/CIO in a multinational manufacturing group.
                        </p>
                        <p>
                            In these years I have been part of many implementations on ERP, production planning tools, consolidation tools and business intelligence.
                        </p>
                        <p>
                            In 2017 I decided that I would have more satisfaction in my life by helping other user-professionals move from good to great and doing it Right First Time with D365 Finance and Operation.
                        </p>
                    </div>
                    <div className="col-12 col-lg-6 p-5">
                        <img className="object-fit-cover w-100 rounded-5 shadow-lg border-0" src="img/brain-7486705_1920.jpg"
                            alt="Skyscrabers" />
                    </div>
                </div>
            </section>

            <section className="container my-5">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 p-5">
                        <img className="object-fit-cover w-100 rounded-5 shadow-lg border-0"
                            src="/img/rohan-makhecha-jw3GOzxiSkw-unsplash.jpg" alt="Skyscrabers" />
                    </div>
                    <div className="col-12 col-lg-6 p-5">
                        <h2>
                            The motivation for  <span className="slogan">Right First Time</span>
                        </h2>
                            <p>
                                One of the last straws that broke the camel's back and made me decide to build my own business and
                                prove that I had the vision
                                to get implementation project Right First Time, was a discussion with a Group CEO in 2017.
                            </p>
                            <p>
                                His honest belief was that (and I quote):
                            </p>
                            <ul>
                                <li>
                                    ALL ERP projects take twice as long and are at least twice as expensive compared to estimation.
                                </li>
                                <li>
                                    The payback and the user satisfaction are always disappointing.
                                </li>
                                <li>
                                    The end result is that either the CFO or the CEO, if not both get sacked
                                </li>
                                <li>
                                    I like my job, so if the current system works, do not fix it.
                                </li>
                            </ul>
                            <p>
                                So in 2017 I started my own business delivering AX2012 and D365 solutions together with various delivery partners,
                                and so far I have proven the CEO wrong (and he was sacked before the company actually did start their successful D365 implementation)
                            </p>
                    </div>
                </div>
            </section>

            <section className="container my-5 bg-dark text-light">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 p-5">
                        <div className="blockquote mb-5">
                            <i className="fa-solid fa-quote-left me-2"></i>
                            If you don't have the time to do it Right First Time, what makes you think you will have time to do
                            it over?
                            <i className="fa-solid fa-quote-right ms-2"></i>
                            <div className="text-end small">
                                - Seth Gordin
                            </div>
                        </div>
                        <div className="blockquote">
                            <i className="fa-solid fa-quote-left me-2"></i>
                            QUALITY is free. It is not a gift, but it is free. The 'unquality' things are what cost money.THINK
                            QUALITY - Do it Right First Time.
                            <i className="fa-solid fa-quote-right ms-2"></i>
                            <div className="text-end small">
                                - Phil Crosby
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 p-5">
                        <img className="object-fit-cover w-100 rounded-5 shadow-lg border-0" src="img/brain-7486707_1920.jpg"
                            alt="Skyscrabers" />
                    </div>
                </div>
            </section>

            <section className="container my-5">
                <div className="card shadow-lg">
                    <div className="card-body">
                        <h2 className="card-title">
                            Lets talk
                        </h2>
                        <p>
                            We would love to look at your case or chat about our offering
                        </p>
                        <ContactUsButtons />
                    </div>
                </div>
            </section>
        </>
    );
}